import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPasswordAPI } from '../../services/AuthService';

type PasswordResetInputs = {
    newPassword: string;
    confirmPassword: string;
};

// Validation Schema
const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .max(20, 'Password cannot exceed 20 characters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Passwords must match')
        .required('Please confirm your password'),
});

const PasswordResetPage: React.FC = () => {
    const { token } = useParams<{ token: string }>();
    const [showPasswords, setShowPasswords] = useState({
        newPassword: false,
        confirmPassword: false,
    });
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<PasswordResetInputs>({
        resolver: yupResolver(validationSchema),
    });

    const togglePasswordVisibility = (
        field: 'newPassword' | 'confirmPassword'
    ) => {
        setShowPasswords((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    const handlePasswordReset = async (data: PasswordResetInputs) => {
        if (!token) {
            toast.error('Invalid or missing reset token');
            return;
        }

        try {
            const response = await resetPasswordAPI(token, data.newPassword);
            if ('success' in response && response.success) {
                toast.success(response.message);
                navigate('/login');
            } else {
                toast.error('Unexpected API response');
            }
        } catch (error: any) {
            toast.error(error.message || 'Unexpected error occurred');
        }
    };

    return (
        <section className="bg-gray-800">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full rounded-lg shadow border sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
                            Reset Your Password
                        </h1>
                        <form
                            className="space-y-4 md:space-y-6"
                            onSubmit={handleSubmit(handlePasswordReset)}
                        >
                            {['newPassword', 'confirmPassword'].map((field) => (
                                <div key={field}>
                                    <label
                                        htmlFor={field}
                                        className="block mb-2 text-sm font-medium text-white"
                                    >
                                        {field === 'newPassword'
                                            ? 'New Password'
                                            : 'Confirm Password'}
                                    </label>
                                    <div className="relative">
                                        <input
                                            type={
                                                showPasswords[
                                                    field as
                                                        | 'newPassword'
                                                        | 'confirmPassword'
                                                ]
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            id={field}
                                            className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                            placeholder={
                                                field === 'newPassword'
                                                    ? 'New Password'
                                                    : 'Confirm Password'
                                            }
                                            {...register(
                                                field as keyof PasswordResetInputs
                                            )}
                                        />
                                        <button
                                            type="button"
                                            className={`absolute inset-y-0 right-0 flex items-center px-3 text-gray-400 transition-opacity ${
                                                showPasswords[
                                                    field as
                                                        | 'newPassword'
                                                        | 'confirmPassword'
                                                ]
                                                    ? 'opacity-100'
                                                    : 'opacity-50'
                                            }`}
                                            onClick={() =>
                                                togglePasswordVisibility(
                                                    field as
                                                        | 'newPassword'
                                                        | 'confirmPassword'
                                                )
                                            }
                                        >
                                            👁️
                                        </button>
                                    </div>
                                    {errors[
                                        field as keyof PasswordResetInputs
                                    ] && (
                                        <p className="text-red-400">
                                            {
                                                errors[
                                                    field as keyof PasswordResetInputs
                                                ]?.message
                                            }
                                        </p>
                                    )}
                                </div>
                            ))}
                            <button
                                type="submit"
                                className="w-full text-white bg-green-400 hover:bg-primary-700 rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                Reset Password
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PasswordResetPage;
