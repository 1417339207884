import React, { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../context/useAuth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import EmailConfirmComponent from '../../components/EmailConfirm';
import PasswordResetComponent from '../../components/PasswordReset';

// Types
type LoginFormInputs = {
    emailOrUsername: string;
    password: string;
};

// Validation Schema
const validationSchema = Yup.object().shape({
    emailOrUsername: Yup.string().required('Email or Username is required'),
    password: Yup.string().required('Password is required'),
});

// Separate Components
const LoginForm: React.FC<{
    register: any;
    handleSubmit: any;
    handleLogin: (data: LoginFormInputs) => Promise<void>;
    errors: any;
    showPassword: boolean;
    setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
    handlePasswordReset: () => void;
}> = ({
    register,
    handleSubmit,
    handleLogin,
    errors,
    showPassword,
    setShowPassword,
    handlePasswordReset,
}) => (
    <form
        className="space-y-4 md:space-y-6"
        onSubmit={handleSubmit(handleLogin)}
    >
        <div>
            <label
                htmlFor="emailOrUsername"
                className="block mb-2 text-sm font-medium text-white"
            >
                Email or Username
            </label>
            <input
                type="text"
                id="emailOrUsername"
                className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                placeholder="Email or Username"
                {...register('emailOrUsername')}
            />
            {errors.emailOrUsername && (
                <p className="text-red-400">{errors.emailOrUsername.message}</p>
            )}
        </div>
        <div>
            <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-white"
            >
                Password
            </label>
            <div className="relative">
                <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Password"
                    {...register('password')}
                />
                <button
                    type="button"
                    className={`absolute inset-y-0 right-0 flex items-center px-3 text-gray-400 transition-opacity ${
                        showPassword ? 'opacity-100' : 'opacity-50'
                    }`}
                    onClick={() => setShowPassword(!showPassword)}
                >
                    👁️
                </button>
            </div>
            {errors.password && (
                <p className="text-red-400">{errors.password.message}</p>
            )}
        </div>
        <button
            type="submit"
            className="w-full text-white bg-green-400 hover:bg-primary-700 rounded-lg text-sm px-5 py-2.5 text-center"
        >
            Login
        </button>
        <div className="flex flex-col gap-0">
            <p className="text-sm font-light text-gray-400">
                Don't have an account?{' '}
                <a
                    href="/register"
                    className="font-medium text-primary-600 hover:underline"
                >
                    Sign up
                </a>
            </p>
            <p className="text-sm font-light text-gray-400">
                Password lost?{' '}
                <button
                    onClick={handlePasswordReset}
                    className="font-medium text-primary-600 hover:underline"
                >
                    Reset here
                </button>
            </p>
        </div>
    </form>
);

// Main Component
const LoginPage: React.FC = () => {
    const { loginUser } = useAuth();
    const navigate = useNavigate();
    const [isAuthCodeSent, setIsAuthCodeSent] = useState(false);
    const [isPasswordLost, setIsPasswordLost] = useState(false);
    const [email, setEmail] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormInputs>({
        resolver: yupResolver(validationSchema),
    });

    const handleLogin = async (data: LoginFormInputs) => {
        try {
            const response = await loginUser(
                data.emailOrUsername,
                data.password
            );
            if (response.success) {
                toast.success(response.message);
                navigate('/');
            } else if (response.message === 'Confirm Email') {
                setEmail(data.emailOrUsername);
                setIsAuthCodeSent(true);
                toast.error('You have to confirm your email');
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error('Login failed');
        }
    };

    const handlePasswordReset = () => {
        setIsPasswordLost(true);
    };

    const handleEmailConfirmSuccess = () => {
        toast.success('Email confirmed successfully.');
        navigate('/');
    };

    const handlePasswordResetSuccess = () => {
        navigate('/login');
    };

    return (
        <section className="bg-gray-800">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full rounded-lg shadow border md:mb-20 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        {isPasswordLost ? (
                            <PasswordResetComponent
                                email={email}
                                onSuccess={handlePasswordResetSuccess}
                            />
                        ) : isAuthCodeSent ? (
                            <EmailConfirmComponent
                                email={email}
                                onSuccess={handleEmailConfirmSuccess}
                            />
                        ) : (
                            <LoginForm
                                register={register}
                                handleSubmit={handleSubmit}
                                handleLogin={handleLogin}
                                errors={errors}
                                showPassword={showPassword}
                                setShowPassword={setShowPassword}
                                handlePasswordReset={handlePasswordReset}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LoginPage;
