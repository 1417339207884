import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { cloneWorkoutGroupApi } from '../../services/WorkoutGroupService';

type CloneWorkoutGroupInputs = {
    code: string;
};

const validationSchema = Yup.object().shape({
    code: Yup.string()
        .required('Group code is required')
        .matches(
            /^[A-Za-z0-9]{8}$/,
            'Group code must be 8 alphanumeric characters'
        ),
});

type CloneWorkoutGroupModalProps = {
    onClose: () => void;
    onSuccess: () => void;
};

const CloneWorkoutGroupModal: React.FC<CloneWorkoutGroupModalProps> = ({
    onClose,
    onSuccess,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<CloneWorkoutGroupInputs>({
        resolver: yupResolver(validationSchema),
    });

    const handleCloneGroup = async (data: CloneWorkoutGroupInputs) => {
        try {
            const response = await cloneWorkoutGroupApi(data.code);
            if ('success' in response && response.success) {
                toast.success(response.message);
                onSuccess();
            } else if ('error' in response) {
                toast.error(response.error);
            } else {
                toast.error('Unexpected error occurred');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    return (
        <div className="bg-gray-900 p-6 rounded-lg shadow-lg text-white">
            <h3 className="text-2xl font-bold mb-4">Clone Workout Group</h3>
            <p className="mb-4 text-gray-400">
                Enter a valid workout group code from another user to clone
                their workout group.
            </p>
            <form
                onSubmit={handleSubmit(handleCloneGroup)}
                className="space-y-4"
            >
                <div>
                    <label
                        htmlFor="code"
                        className="block my-2 text-sm font-medium text-white"
                    >
                        Group Code
                    </label>
                    <input
                        type="text"
                        id="code"
                        className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Enter group code"
                        {...register('code')}
                    />
                    {errors.code && (
                        <p className="text-red-400">{errors.code.message}</p>
                    )}
                </div>
                <div className="flex justify-end space-x-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CloneWorkoutGroupModal;
