import axios, { AxiosError } from 'axios';
import { handleError } from '../utils/ErrorHandler';
import { UserProfileToken } from '../models/userModel';

const BASE_URL = process.env.REACT_APP_BASE_URL;

interface APIError {
    error: string;
}

export const loginAPI = async (
    emailOrUsername: string,
    password: string
): Promise<UserProfileToken | APIError> => {
    try {
        const response = await axios.post<UserProfileToken>(
            BASE_URL + 'auth/login',
            {
                emailOrUsername,
                password,
            }
        );
        return response.data;
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            if (error.response?.status === 400) {
                return { error: 'Wrong credentials' };
            }
            if (
                error.response?.status === 403 &&
                error.response.data.message === 'Confirm Email'
            ) {
                return { error: error.response.data.message };
            }
        }
        handleError(error);
        return { error: 'Server error' };
    }
};

export const registerAPI = async (
    email: string,
    username: string,
    password: string,
    firstName: string,
    lastName: string,
    inviteKey: string
): Promise<UserProfileToken | APIError> => {
    try {
        const response = await axios.post<UserProfileToken>(
            BASE_URL + 'auth/register',
            {
                email,
                username,
                password,
                firstName,
                lastName,
                inviteKey,
            }
        );
        return response.data;
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            return { error: error.response?.data.message || 'Server error' };
        }
        handleError(error);
        return { error: 'Server error' };
    }
};

export const confirmEmailAPI = async (
    authCode: string
): Promise<{ success: boolean; message: string } | APIError> => {
    try {
        const response = await axios.post<{
            success: boolean;
            message: string;
        }>(BASE_URL + 'auth/confirm-email', {
            authCode,
        });
        return response.data;
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            return { error: error.response?.data.message || 'Server error' };
        }
        handleError(error);
        return { error: 'Server error' };
    }
};

export const requestNewEmailAuthCodeAPI = async (
    email: string
): Promise<{ success: boolean; message: string } | APIError> => {
    try {
        const response = await axios.post<{
            success: boolean;
            message: string;
        }>(BASE_URL + 'auth/auth-code', { email });
        return response.data;
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            return { error: error.response?.data.message || 'Server error' };
        }
        handleError(error);
        return { error: 'Server error' };
    }
};

export const requestPasswordResetAPI = async (
    email: string
): Promise<{ success: boolean; message: string } | APIError> => {
    try {
        const response = await axios.post<{
            success: boolean;
            message: string;
        }>(`${BASE_URL}auth/password-reset-request`, {
            email,
        });
        return response.data;
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            return { error: error.response?.data.message || 'Server error' };
        }
        handleError(error);
        return { error: 'Server error' };
    }
};

export const resetPasswordAPI = async (
    token: string,
    newPassword: string
): Promise<{ success: boolean; message: string } | APIError> => {
    try {
        const response = await axios.post<{
            success: boolean;
            message: string;
        }>(`${BASE_URL}auth/password-reset/${token}`, {
            newPassword,
        });
        return response.data;
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            return { error: error.response?.data.message || 'Server error' };
        }
        handleError(error);
        return { error: 'Server error' };
    }
};

export const updateUserAPI = async (
    token: string,
    updateData: Partial<UserProfileToken>
): Promise<UserProfileToken | APIError> => {
    try {
        const response = await axios.put<UserProfileToken>(
            BASE_URL + 'auth/update',
            updateData
        );
        return response.data;
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            return { error: error.response?.data.message || 'Server error' };
        }
        handleError(error);
        return { error: 'Server error' };
    }
};

export const searchUserAPI = async (
    username: string
): Promise<{ id: number; username: string }[] | APIError> => {
    try {
        const response = await axios.get<
            {
                id: number;
                username: string;
            }[]
        >(`${BASE_URL}users/search/${username}`);
        return response.data;
    } catch (error: unknown) {
        if (error instanceof AxiosError) {
            return { error: error.response?.data.message || 'Server error' };
        }
        handleError(error);
        return { error: 'Server error' };
    }
};
