import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../context/useAuth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import EmailConfirmComponent from '../../components/EmailConfirm';

type RegisterFormInputs = {
    email: string;
    username: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
    inviteKey: string;
    acceptPolicies: boolean;
};

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email format')
        .required('Email is required'),
    username: Yup.string().required('Username is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    password: Yup.string()
        .min(3, 'Password must be at least 3 characters')
        .max(20, 'Password must be at most 20 characters')
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Please confirm your password'),
    inviteKey: Yup.string().required('Invite Key is required'),
    acceptPolicies: Yup.bool()
        .oneOf([true], 'You must accept the policies')
        .required('You must accept the policies'),
});

const RegisterPage: React.FC = () => {
    const { registerUser } = useAuth();
    const navigate = useNavigate();
    const [isAuthCodeSent, setIsAuthCodeSent] = useState(false);
    const [email, setEmail] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RegisterFormInputs>({
        resolver: yupResolver(validationSchema),
    });

    const handleRegister = async (data: RegisterFormInputs) => {
        try {
            const response = await registerUser(
                data.email,
                data.username,
                data.password,
                data.firstName,
                data.lastName,
                data.inviteKey
            );
            if (response.success) {
                setEmail(data.email);
                setIsAuthCodeSent(true);
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            toast.error('Registration failed');
        }
    };

    const handleEmailConfirmSuccess = () => {
        navigate('/login');
    };

    return (
        <section className="bg-gray-800">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full rounded-lg shadow border sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        {isAuthCodeSent ? (
                            <EmailConfirmComponent
                                email={email}
                                onSuccess={handleEmailConfirmSuccess}
                            />
                        ) : (
                            <>
                                <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
                                    Register an account
                                </h1>
                                <form
                                    className="space-y-4 md:space-y-6"
                                    onSubmit={handleSubmit(handleRegister)}
                                >
                                    {[
                                        {
                                            id: 'email',
                                            label: 'Email',
                                            type: 'email',
                                        },
                                        {
                                            id: 'username',
                                            label: 'Username',
                                            type: 'text',
                                        },
                                        {
                                            id: 'firstName',
                                            label: 'First Name',
                                            type: 'text',
                                        },
                                        {
                                            id: 'lastName',
                                            label: 'Last Name',
                                            type: 'text',
                                        },
                                        {
                                            id: 'inviteKey',
                                            label: 'Invite Key',
                                            type: 'text',
                                        },
                                    ].map(({ id, label, type }) => (
                                        <div key={id}>
                                            <label
                                                htmlFor={id}
                                                className="block mb-2 text-sm font-medium text-white"
                                            >
                                                {label}
                                            </label>
                                            <input
                                                type={type}
                                                id={id}
                                                className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                                placeholder={label}
                                                {...register(
                                                    id as keyof RegisterFormInputs
                                                )}
                                            />
                                            {errors[
                                                id as keyof RegisterFormInputs
                                            ] && (
                                                <p className="text-red-400">
                                                    {
                                                        errors[
                                                            id as keyof RegisterFormInputs
                                                        ]?.message
                                                    }
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                    {[
                                        {
                                            id: 'password',
                                            label: 'Password',
                                            show: showPassword,
                                            toggle: setShowPassword,
                                        },
                                        {
                                            id: 'confirmPassword',
                                            label: 'Confirm Password',
                                            show: showConfirmPassword,
                                            toggle: setShowConfirmPassword,
                                        },
                                    ].map(({ id, label, show, toggle }) => (
                                        <div key={id}>
                                            <label
                                                htmlFor={id}
                                                className="block mb-2 text-sm font-medium text-white"
                                            >
                                                {label}
                                            </label>
                                            <div className="relative">
                                                <input
                                                    type={
                                                        show
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    id={id}
                                                    className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                                    placeholder={label}
                                                    {...register(
                                                        id as keyof RegisterFormInputs
                                                    )}
                                                />
                                                <button
                                                    type="button"
                                                    className={`absolute inset-y-0 right-0 flex items-center px-3 text-gray-400 transition-opacity ${
                                                        show
                                                            ? 'opacity-100'
                                                            : 'opacity-50'
                                                    }`}
                                                    onClick={() =>
                                                        toggle(!show)
                                                    }
                                                >
                                                    👁️
                                                </button>
                                            </div>
                                            {errors[
                                                id as keyof RegisterFormInputs
                                            ] && (
                                                <p className="text-red-400">
                                                    {
                                                        errors[
                                                            id as keyof RegisterFormInputs
                                                        ]?.message
                                                    }
                                                </p>
                                            )}
                                        </div>
                                    ))}
                                    <div className="flex items-center">
                                        <input
                                            type="checkbox"
                                            id="acceptPolicies"
                                            className="mr-2"
                                            {...register('acceptPolicies')}
                                        />
                                        <label
                                            htmlFor="acceptPolicies"
                                            className="text-sm text-white"
                                        >
                                            I have read and accept the{' '}
                                            <a
                                                href="/privacy-policy"
                                                className="text-blue-500 hover:underline"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Privacy Policy
                                            </a>
                                            ,{' '}
                                            <a
                                                href="/cookie-policy"
                                                className="text-blue-500 hover:underline"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Cookie Policy
                                            </a>{' '}
                                            and{' '}
                                            <a
                                                href="/tos"
                                                className="text-blue-500 hover:underline"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Terms of Service
                                            </a>
                                            .
                                        </label>
                                    </div>
                                    {errors.acceptPolicies && (
                                        <p className="text-red-400">
                                            {errors.acceptPolicies.message}
                                        </p>
                                    )}
                                    <button
                                        type="submit"
                                        className="w-full text-white bg-green-400 hover:bg-primary-700 rounded-lg text-sm px-5 py-2.5 text-center"
                                    >
                                        Sign up
                                    </button>
                                    <p className="text-sm font-light text-gray-400">
                                        Already have an account?{' '}
                                        <a
                                            href="/login"
                                            className="font-medium text-primary-600 hover:underline"
                                        >
                                            Sign in
                                        </a>
                                    </p>
                                </form>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RegisterPage;
