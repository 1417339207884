import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { FaStar } from 'react-icons/fa';
import { AddExerciseDto } from '../../models/exerciseModel';

const muscleGroupMap: { [key: number]: string } = {
    0: 'Arms',
    1: 'Abs',
    2: 'Chest',
    3: 'Back',
    4: 'Shoulders',
    5: 'Legs',
    6: 'Glutes',
};

const equipmentMap: { [key: number]: string } = {
    0: 'Free Weights',
    1: 'Benches/Racks',
    2: 'Body Weight',
    3: 'Cable Machines',
    4: 'Machines',
};

interface AddExerciseFormProps {
    onSave: (exercise: AddExerciseDto) => void;
    onCancel: () => void;
}

const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    description: yup.string().required('Description is required'),
    explanation: yup.string(),
    difficulty: yup.number().required('Difficulty is required').min(1).max(3),
    muscleGroup: yup.number().required('Muscle group is required'),
    equipment: yup.number().required('Equipment is required'),
});

const AddExerciseForm: React.FC<AddExerciseFormProps> = ({
    onSave,
    onCancel,
}) => {
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            explanation: '',
            difficulty: 1,
            muscleGroup: 0,
            equipment: 0,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            onSave({
                ...values,
                muscleGroup: parseInt(values.muscleGroup.toString(), 10),
                equipment: parseInt(values.equipment.toString(), 10),
            });
        },
    });

    const renderDifficultyStars = () => {
        return [1, 2, 3].map((star) => (
            <FaStar
                key={star}
                className={`cursor-pointer ${
                    formik.values.difficulty >= star
                        ? 'text-yellow-500'
                        : 'text-gray-400'
                }`}
                onClick={() => formik.setFieldValue('difficulty', star)}
            />
        ));
    };

    return (
        <div className="bg-gray-900 p-4 rounded-lg shadow-md max-w-2xl mx-auto my-8">
            <h3 className="text-2xl mb-4 text-white">Add Your Own Exercise</h3>
            <form onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                    <label className="block text-white mb-2">Name</label>
                    <input
                        className="input input-bordered w-full bg-gray-800 text-white p-2 rounded-md"
                        type="text"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div className="text-red-500 text-sm">
                            {formik.errors.name}
                        </div>
                    ) : null}
                </div>
                <div className="mb-4">
                    <label className="block text-white mb-2">Description</label>
                    <textarea
                        className="textarea textarea-bordered w-full bg-gray-800 text-white p-2 rounded-md"
                        name="description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                    />
                    {formik.touched.description && formik.errors.description ? (
                        <div className="text-red-500 text-sm">
                            {formik.errors.description}
                        </div>
                    ) : null}
                </div>
                <div className="mb-4">
                    <label className="block text-white mb-2">Explanation</label>
                    <textarea
                        className="textarea textarea-bordered w-full bg-gray-800 text-white p-2 rounded-md"
                        name="explanation"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.explanation}
                    />
                    {formik.touched.explanation && formik.errors.explanation ? (
                        <div className="text-red-500 text-sm">
                            {formik.errors.explanation}
                        </div>
                    ) : null}
                </div>
                <div className="mb-4">
                    <label className="block text-white mb-2">Difficulty</label>
                    <div className="flex">{renderDifficultyStars()}</div>
                    {formik.touched.difficulty && formik.errors.difficulty ? (
                        <div className="text-red-500 text-sm">
                            {formik.errors.difficulty}
                        </div>
                    ) : null}
                </div>
                <div className="mb-4">
                    <label className="block text-white mb-2">
                        Muscle Group
                    </label>
                    <select
                        className="select select-bordered w-full bg-gray-800 text-white p-2 rounded-md"
                        name="muscleGroup"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.muscleGroup}
                    >
                        {Object.entries(muscleGroupMap).map(([key, value]) => (
                            <option key={key} value={key}>
                                {value}
                            </option>
                        ))}
                    </select>
                    {formik.touched.muscleGroup && formik.errors.muscleGroup ? (
                        <div className="text-red-500 text-sm">
                            {formik.errors.muscleGroup}
                        </div>
                    ) : null}
                </div>
                <div className="mb-4">
                    <label className="block text-white mb-2">Equipment</label>
                    <select
                        className="select select-bordered w-full bg-gray-800 text-white p-2 rounded-md"
                        name="equipment"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.equipment}
                    >
                        {Object.entries(equipmentMap).map(([key, value]) => (
                            <option key={key} value={key}>
                                {value}
                            </option>
                        ))}
                    </select>
                    {formik.touched.equipment && formik.errors.equipment ? (
                        <div className="text-red-500 text-sm">
                            {formik.errors.equipment}
                        </div>
                    ) : null}
                </div>
                <div className="flex justify-end space-x-4">
                    <button
                        type="button"
                        className="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 rounded"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddExerciseForm;
