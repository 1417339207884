import React, { useState, useEffect } from 'react';
import {
    createWorkoutGroupApi,
    getWorkoutGroupsApi,
} from '../../services/WorkoutGroupService';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import CloneWorkoutGroupModal from './CloneWorkoutGroupModal';

const WorkoutGroupManager: React.FC<{
    onGroupSelect: (groupId: number, groupCode: string) => void;
    onCreateNewGroup: () => void;
}> = ({ onGroupSelect, onCreateNewGroup }) => {
    const [groups, setGroups] = useState<any[]>([]);
    const [groupName, setGroupName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false);
    const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);

    useEffect(() => {
        loadGroups();
    }, []);

    const loadGroups = async () => {
        try {
            const res = await getWorkoutGroupsApi();
            if (res && res.data) {
                setGroups(res.data);
            }
        } catch (e) {
            console.error('Failed to load workout groups', e);
        }
    };

    const handleCreateGroup = async () => {
        try {
            await createWorkoutGroupApi({ name: groupName, description });
            setGroupName('');
            setDescription('');
            setIsCreatingNewGroup(false);
            loadGroups();
        } catch (e) {
            console.error('Failed to create workout group', e);
        }
    };

    const handleCloneSuccess = () => {
        loadGroups();
        setIsCloneModalOpen(false);
    };

    return (
        <div className="bg-gray-800 p-4 rounded-lg shadow-md mb-4">
            <div className="flex flex-wrap gap-4 mb-4">
                {groups.map((group) => (
                    <button
                        key={group.id}
                        onClick={() => onGroupSelect(group.id, group.code)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {group.name}
                    </button>
                ))}
            </div>
            <div className="flex gap-4 mb-4">
                <button
                    onClick={() => setIsCreatingNewGroup(true)}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    +
                </button>
                <button
                    onClick={() => setIsCloneModalOpen(true)}
                    className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded"
                    style={{ whiteSpace: 'nowrap' }}
                >
                    Code
                </button>
            </div>

            {isCreatingNewGroup && (
                <div className="mt-4">
                    <input
                        type="text"
                        placeholder="Group Name"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                    />
                    <textarea
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className="my-2 border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                    ></textarea>
                    <button
                        onClick={handleCreateGroup}
                        className="bg-green-500 text-white p-2 rounded w-full"
                    >
                        Create Group
                    </button>
                </div>
            )}
            <Modal
                isOpen={isCloneModalOpen}
                onRequestClose={() => setIsCloneModalOpen(false)}
                contentLabel="Clone Workout Group"
                className="Modal"
                overlayClassName="Overlay"
            >
                <CloneWorkoutGroupModal
                    onClose={() => setIsCloneModalOpen(false)}
                    onSuccess={handleCloneSuccess}
                />
            </Modal>
        </div>
    );
};

export default WorkoutGroupManager;
