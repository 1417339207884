import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    fetchLatestBodyStats,
    saveBodyStats,
} from '../services/BodyStatsService';
import StatComponent from '../components/BodyStat';

interface Measurement {
    name: string;
    value: number;
}

type BodyStatKeys =
    | 'bodyWeight'
    | 'bodyHeight'
    | 'shoulder'
    | 'chest'
    | 'waist'
    | 'thighLeft'
    | 'thighRight'
    | 'calfLeft'
    | 'calfRight'
    | 'bicepsLeft'
    | 'bicepsRight';

interface BodyStat {
    [key: string]: Measurement[];
}

// Helper function to initialize empty body stats
const initializeDefaultBodyStats = (): BodyStat =>
    Object.fromEntries(
        [
            'bodyWeight',
            'bodyHeight',
            'shoulder',
            'chest',
            'waist',
            'thighLeft',
            'thighRight',
            'calfLeft',
            'calfRight',
            'bicepsLeft',
            'bicepsRight',
        ].map((key) => [key, []])
    ) as BodyStat;

const BodyStatsPage: React.FC = () => {
    // State variables
    const [bodyStats, setBodyStats] = useState<BodyStat>(
        initializeDefaultBodyStats()
    );
    const [currentInputs, setCurrentInputs] = useState<
        Record<BodyStatKeys, string>
    >(
        () =>
            ({
                bodyWeight: '',
                bodyHeight: '',
                shoulder: '',
                chest: '',
                waist: '',
                thighLeft: '',
                thighRight: '',
                calfLeft: '',
                calfRight: '',
                bicepsLeft: '',
                bicepsRight: '',
            }) as Record<BodyStatKeys, string>
    );
    const [showInstructions, setShowInstructions] = useState(false);

    // Fetch latest body stats from the backend
    useEffect(() => {
        const loadInitialData = async () => {
            try {
                const latestStats = await fetchLatestBodyStats();
                if (latestStats.length > 0) {
                    // Sort stats by creation date
                    latestStats.sort(
                        (a, b) =>
                            new Date(a.createdAt).getTime() -
                            new Date(b.createdAt).getTime()
                    );

                    // Format stats into the BodyStat structure
                    const formattedStats = initializeDefaultBodyStats();
                    latestStats.forEach((stat) => {
                        const date = new Date(
                            stat.createdAt
                        ).toLocaleDateString('de-DE', {
                            day: '2-digit',
                            month: '2-digit',
                        });
                        Object.entries(stat).forEach(([key, value]) => {
                            if (key !== 'id' && key !== 'createdAt') {
                                formattedStats[key]?.push({
                                    name: date,
                                    value: value as number,
                                });
                            }
                        });
                    });

                    setBodyStats(formattedStats);
                }
            } catch (error) {
                toast.error('Failed to load body stats');
            }
        };

        loadInitialData();
    }, []);

    // Handle save operation
    const handleSave = async () => {
        try {
            const formattedData = (
                Object.keys(bodyStats) as BodyStatKeys[]
            ).reduce(
                (acc, key) => {
                    const inputValue = parseFloat(currentInputs[key] || '');
                    acc[key] = isNaN(inputValue)
                        ? bodyStats[key]?.[bodyStats[key].length - 1]?.value ||
                          0
                        : inputValue;
                    return acc;
                },
                {} as Record<BodyStatKeys, number>
            );

            await saveBodyStats(formattedData);
            toast.success('Data saved!');
            setTimeout(() => window.location.reload(), 1000);
        } catch (error) {
            toast.error('Failed to save data');
        }
    };

    // Handle input change
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        key: BodyStatKeys
    ) => {
        setCurrentInputs((prev) => ({
            ...prev,
            [key]: event.target.value,
        }));
    };

    return (
        <div className="container mx-auto p-4 text-white min-h-screen">
            <h2 className="text-3xl font-bold mb-4">Track Body Stats</h2>
            <p className="text-gray-400 mb-8">
                On this page, you can track various body measurements over time.
                Enter your current measurements and save them to see the
                progress.{' '}
                <span
                    className="text-blue-500 underline cursor-pointer"
                    onClick={() => setShowInstructions((prev) => !prev)}
                >
                    Click here
                </span>{' '}
                to see instructions on how to take measurements.
            </p>

            {/* Instruction section */}
            {showInstructions && (
                <div className="bg-gray-700 p-6 rounded-lg mb-4">
                    <h3 className="text-lg font-bold mb-2">
                        How to Take Measurements
                    </h3>
                    <ul className="list-disc list-inside text-gray-300">
                        {[
                            {
                                name: 'Body Weight',
                                description:
                                    'Weigh yourself in the morning after using the bathroom.',
                            },
                            {
                                name: 'Body Height',
                                description:
                                    'Stand straight against a wall and measure your height.',
                            },
                            {
                                name: 'Shoulder',
                                description:
                                    'Measure around the widest part of your shoulders.',
                            },
                            {
                                name: 'Chest',
                                description:
                                    'Measure around the fullest part of your chest.',
                            },
                            {
                                name: 'Waist',
                                description:
                                    'Measure around the narrowest part of your waist.',
                            },
                            {
                                name: 'Thigh (Left/Right)',
                                description:
                                    'Measure around the fullest part of your thigh.',
                            },
                            {
                                name: 'Calf (Left/Right)',
                                description:
                                    'Measure around the widest part of your calf.',
                            },
                            {
                                name: 'Biceps (Left/Right)',
                                description:
                                    'Measure around the widest part of your biceps.',
                            },
                        ].map((item, index) => (
                            <li key={index}>
                                <strong>{item.name}:</strong> {item.description}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {/* Stats input section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
                {(Object.keys(bodyStats) as BodyStatKeys[]).map((key) => (
                    <StatComponent
                        key={key}
                        label={key
                            .replace(/([A-Z])/g, ' $1')
                            .replace(/^./, (str) => str.toUpperCase())}
                        data={bodyStats[key]}
                        value={currentInputs[key] || ''}
                        onChange={(event) => handleChange(event, key)}
                        unit={key.includes('Weight') ? 'kg' : 'cm'}
                    />
                ))}
            </div>

            <button
                onClick={handleSave}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
                Save Data
            </button>
        </div>
    );
};

export default BodyStatsPage;
