import axios from 'axios';
import { handleError } from '../utils/ErrorHandler';
import {
    AddExerciseDto,
    ExerciseSearchResponse,
    Workout,
} from '../models/exerciseModel';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const searchExerciseApi = async (
    name: string
): Promise<ExerciseSearchResponse> => {
    try {
        const { data } = await axios.get<ExerciseSearchResponse>(
            `${BASE_URL}exercise/search/${name}`
        );
        return data;
    } catch (error) {
        throw error;
    }
};

export const addExerciseApi = async (exercise: AddExerciseDto) => {
    try {
        const response = await axios.post(
            `${BASE_URL}exercise/add/private`,
            exercise
        );
        return response.data;
    } catch (error) {
        handleError(error);
    }
};

export const saveWorkoutApi = async (
    groupId: number | null,
    exercises: Workout['exercises']
) => {
    try {
        const data = await axios.post(`${BASE_URL}workout/add`, {
            groupId,
            exercises: exercises.map((ex) => ({
                exerciseId: ex.exercise.id,
                sets: ex.sets,
            })),
        });
        return data;
    } catch (error) {
        handleError(error);
    }
};

export const deleteWorkoutGroupApi = async (groupId: number) => {
    try {
        const data = await axios.delete(`${BASE_URL}workout/group/${groupId}`);
        return data;
    } catch (error) {
        handleError(error);
    }
};

export const getLastWorkoutByGroupIdApi = async (groupId: number) => {
    try {
        const data = await axios.get(
            `${BASE_URL}workout/group/last/${groupId}`
        );
        return data;
    } catch (error) {
        handleError(error);
    }
};
