import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { requestPasswordResetAPI } from '../services/AuthService';

type PasswordResetRequestInputs = {
    email: string;
};

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
});

type PasswordResetComponentProps = {
    email: string;
    onSuccess: () => void;
};

const PasswordResetComponent: React.FC<PasswordResetComponentProps> = ({
    email,
    onSuccess,
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<PasswordResetRequestInputs>({
        resolver: yupResolver(validationSchema),
    });

    const handlePasswordResetRequest = async (
        data: PasswordResetRequestInputs
    ) => {
        try {
            const response = await requestPasswordResetAPI(data.email);
            if ('success' in response && response.success) {
                toast.success(response.message);
                onSuccess();
            } else if ('error' in response) {
                toast.error(response.error);
            } else {
                toast.error('Unexpected error occurred');
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    return (
        <div>
            <h1 className="text-xl font-bold leading-tight tracking-tight mb-3 md:text-2xl text-white">
                Password Reset
            </h1>
            <p className="text-gray-400">
                Please enter an email address where an authorization code will
                be sent to reset your password.
            </p>
            <form
                className="space-y-4 md:space-y-6"
                onSubmit={handleSubmit(handlePasswordResetRequest)}
            >
                <div>
                    <label
                        htmlFor="email"
                        className="block my-2 text-sm font-medium text-white"
                    >
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Enter your email"
                        {...register('email')}
                    />
                    {errors.email && (
                        <p className="text-red-400">{errors.email.message}</p>
                    )}
                </div>
                <button
                    type="submit"
                    className="w-full text-white bg-green-400 hover:bg-primary-700 rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Send Reset Link
                </button>
            </form>
        </div>
    );
};

export default PasswordResetComponent;
