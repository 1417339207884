import React, { useEffect } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './context/useAuth';
import { Outlet } from 'react-router-dom';
import Navbar from './components/Navbar';
import { setFavicon } from './utils/setFavicon';
import logo from './assets/images/logo.png';
import Footer from './components/Footer';
import ErrorBoundary from './utils/ErrorBoundary';

const App = () => {
    useEffect(() => {
        document.title = 'Gym Buddy';
        setFavicon(logo);
    }, []);

    return (
        <div className="bg-gray-800 text-white min-h-screen flex flex-col">
            <ErrorBoundary>
                <UserProvider>
                    <Navbar />
                    <div className="flex-grow mx-5">
                        <Outlet />
                    </div>
                    <Footer />
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover={false}
                        theme="dark"
                        transition={Zoom}
                    />
                </UserProvider>
            </ErrorBoundary>
        </div>
    );
};

export default App;
