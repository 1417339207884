import axios from 'axios';
import { handleError } from '../utils/ErrorHandler';
import { toast } from 'react-toastify';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchLatestBodyStats = async (): Promise<any[]> => {
    try {
        const res = await axios.get(BASE_URL + 'bodystats/last');
        return res.data;
    } catch (error: any) {
        if (error.code === 'ERR_BAD_REQUEST') {
            toast.warn('No data found');
        } else {
            handleError(error);
        }

        return [];
    }
};

export const saveBodyStats = async (data: any): Promise<any> => {
    try {
        const res = await axios.post(BASE_URL + 'bodystats/add', data);
        return res.data;
    } catch (error) {
        handleError(error);
        return null;
    }
};
