import React, { useEffect, useState } from 'react';
import { getInvitesAPI, assignInviteAPI } from '../../services/InviteService';
import { searchUserAPI } from '../../services/AuthService';
import { Invite } from '../../models/userModel';
import { useAuth } from '../../context/useAuth';
import { toast } from 'react-toastify';

// Komponenten für Einladungen
const InviteTable = ({
    invites,
    title,
}: {
    invites: Invite[];
    title: string;
}) => (
    <div>
        <h2 className="text-xl font-bold mt-8 mb-4 text-white">{title}</h2>
        <div className="overflow-x-auto">
            <table className="min-w-full bg-gray-800 text-white rounded-lg shadow-lg">
                <thead>
                    <tr className="bg-gray-700">
                        <th className="py-2 px-4">Invite Key</th>
                        <th className="py-2 px-4">Used By</th>
                        <th className="py-2 px-4">Creator</th>
                    </tr>
                </thead>
                <tbody>
                    {invites.map((invite) => (
                        <tr
                            key={invite.id}
                            className="border-t border-gray-700"
                        >
                            <td className="py-2 px-4">{invite.key}</td>
                            <td className="py-2 px-4">
                                {invite.usedByUser?.username || 'Unused'}
                            </td>
                            <td className="py-2 px-4">
                                {invite.creator.username}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
);

// Admin-Komponente zur Verwaltung von Einladungen
const AdminInviteAssign = ({
    searchQuery,
    setSearchQuery,
    searchResults,
    handleSearch,
    handleAssignInvite,
    assigning,
}: {
    searchQuery: string;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
    searchResults: { id: number; username: string }[];
    handleSearch: () => Promise<void>;
    handleAssignInvite: (userId: number) => Promise<void>;
    assigning: boolean;
}) => (
    <div className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-white">Assign Invites</h2>
        <div className="flex gap-4">
            <input
                type="text"
                className="p-2 border border-gray-600 rounded bg-gray-700 text-white"
                placeholder="Search for a user"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button
                className="px-4 py-2 bg-green-500 text-white rounded"
                onClick={handleSearch}
            >
                Search
            </button>
        </div>
        {searchResults.length > 0 && (
            <ul className="mt-4 bg-gray-800 rounded p-4">
                {searchResults.map((result) => (
                    <li
                        key={result.id}
                        className="flex justify-between items-center border-b border-gray-700 py-2"
                    >
                        <span>
                            {result.username} (ID: {result.id})
                        </span>
                        <button
                            onClick={() => handleAssignInvite(result.id)}
                            disabled={assigning}
                            className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
                        >
                            {assigning ? 'Assigning...' : 'Assign Invite'}
                        </button>
                    </li>
                ))}
            </ul>
        )}
    </div>
);

// Hauptkomponente
const InvitesPage: React.FC = () => {
    const { user } = useAuth();
    const [invites, setInvites] = useState<Invite[]>([]);
    const [unusedInvites, setUnusedInvites] = useState<Invite[]>([]);
    const [usedInvites, setUsedInvites] = useState<Invite[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState<
        { id: number; username: string }[]
    >([]);
    const [assigning, setAssigning] = useState(false);

    // API-Aufruf: Einladungen abrufen
    useEffect(() => {
        const fetchInvites = async () => {
            const result = await getInvitesAPI();
            if ('error' in result) {
                toast.error(result.error);
            } else {
                setInvites(result);
            }
        };
        fetchInvites();
    }, []);

    // Einladungen filtern
    useEffect(() => {
        setUnusedInvites(invites.filter((invite) => !invite.usedByUser));
        setUsedInvites(invites.filter((invite) => invite.usedByUser));
    }, [invites]);

    // Benutzer suchen
    const handleSearch = async () => {
        const result = await searchUserAPI(searchQuery);
        if ('error' in result) {
            toast.error(result.error);
        } else {
            setSearchResults(result);
        }
    };

    // Einladung zuweisen
    const handleAssignInvite = async (userId: number) => {
        setAssigning(true);
        try {
            const response = await assignInviteAPI(userId);
            if ('success' in response && response.success) {
                toast.success(response.message);
            } else if ('error' in response) {
                toast.error(response.error);
            } else {
                toast.error('Unexpected API response');
            }
        } catch (error: any) {
            toast.error(error.message || 'Unexpected error occurred');
        }
        setAssigning(false);
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4 text-white">My Invites</h1>
            {user?.isAdmin && (
                <AdminInviteAssign
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    searchResults={searchResults}
                    handleSearch={handleSearch}
                    handleAssignInvite={handleAssignInvite}
                    assigning={assigning}
                />
            )}
            {unusedInvites.length > 0 ? (
                <InviteTable invites={unusedInvites} title="Unused Invites" />
            ) : (
                <p className="text-yellow-300">
                    Unfortunately you don't have any invites to invite other
                    users to Gym Buddy yet.
                </p>
            )}
            {usedInvites.length > 0 && (
                <InviteTable invites={usedInvites} title="Used Invites" />
            )}
        </div>
    );
};

export default InvitesPage;
