import React, { useEffect, useState } from 'react';
import logo from '../assets/images/logo.png';
import avatar0 from '../assets/images/profile/0.png';
import avatar1 from '../assets/images/profile/1.png';
import avatar2 from '../assets/images/profile/2.png';
import avatar3 from '../assets/images/profile/3.png';
import avatar4 from '../assets/images/profile/4.png';
import avatar5 from '../assets/images/profile/5.png';
import { useAuth } from '../context/useAuth';
import { Link } from 'react-router-dom';

interface Props {}

const Navbar: React.FC = (props: Props) => {
    const { isLoggedIn, user, logout } = useAuth();
    const [state, setState] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const navigation = [
        { title: 'Workouts', path: 'workouts', protected: true },
        { title: 'Body Stats', path: 'bodystats', protected: true },
    ];

    // Map avatars to an array for easy access
    const avatars = [avatar0, avatar1, avatar2, avatar3, avatar4, avatar5];

    useEffect(() => {
        const handleDocumentClick = (e: MouseEvent) => {
            const target = e.target as Element;
            if (
                !target.closest('.menu-btn') &&
                !target.closest('.dropdown-btn')
            ) {
                setState(false);
                setDropdownOpen(false);
            }
        };
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <nav
            className={`bg-gray-900 md:text-base ${
                state
                    ? 'shadow-lg rounded-xl border mx-2 mt-2 md:shadow-none md:border-none md:mx-2 md:mt-0'
                    : ''
            }`}
        >
            <div className="gap-x-14 items-center max-w-screen-xl mx-auto px-4 md:flex md:px-8">
                <div className="flex items-center justify-between py-5 md:block">
                    <a href="/">
                        <img src={logo} width={100} height={100} alt="logo" />
                    </a>
                    <div className="md:hidden">
                        <button
                            className="menu-btn text-gray-500 hover:text-gray-800"
                            onClick={() => setState(!state)}
                        >
                            {state ? (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
                <div
                    className={`flex-1 items-center mt-8 md:mt-0 md:flex ${
                        state ? 'block' : 'hidden'
                    } `}
                >
                    <ul className="justify-center items-center space-y-6 md:flex md:space-x-6 md:space-y-0">
                        {navigation.map((item, idx) => {
                            if (item.protected && !isLoggedIn()) return null;
                            return (
                                <li
                                    key={idx}
                                    className="text-gray-200 hover:text-white"
                                >
                                    <a href={item.path} className="block">
                                        {item.title}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                    {isLoggedIn() ? (
                        <div className="flex-1 gap-x-6 items-center justify-end mt-6 space-y-6 md:flex md:space-y-0 md:mt-0">
                            <div className="relative">
                                <div
                                    className="flex items-center gap-4 cursor-pointer dropdown-btn"
                                    onClick={toggleDropdown}
                                >
                                    <img
                                        className="w-10 h-10 rounded-full"
                                        src={
                                            user?.avatar !== undefined
                                                ? avatars[user.avatar]
                                                : avatars[0]
                                        }
                                        alt="User Avatar"
                                    />
                                    <div className="font-medium text-white">
                                        <div>{user?.username}</div>
                                        <div className="text-sm text-gray-500 dark:text-gray-400">
                                            Made with ❤️
                                        </div>
                                    </div>
                                </div>
                                {dropdownOpen && (
                                    <div className="absolute right-0 mt-2 w-48 bg-gray-900 text-white rounded-md shadow-lg py-2">
                                        <Link
                                            to="/user-settings"
                                            className="block px-4 py-2 hover:bg-gray-800"
                                        >
                                            User Settings
                                        </Link>
                                        <Link
                                            to="/invites"
                                            className="block px-4 py-2 hover:bg-gray-800"
                                        >
                                            Invites
                                        </Link>
                                        <button
                                            onClick={logout}
                                            className="block w-full text-left px-4 py-2 text-red-600 hover:bg-gray-800"
                                        >
                                            Sign Out
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="flex-1 gap-x-6 items-center justify-end mt-6 space-y-6 md:flex md:space-y-0 md:mt-0">
                            <Link
                                to="/register"
                                className="block text-gray-200 hover:text-white"
                            >
                                Register
                            </Link>
                            <Link
                                to="/login"
                                className="flex items-center justify-center gap-x-1 py-2 px-4 text-white font-medium bg-blue-900 hover:bg-gray-700 active:bg-gray-900 rounded-full md:inline-flex"
                            >
                                Login
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
